import * as React from 'react';
import * as styles from './magenta-loyalty-club.module.css';

import { FunctionComponent, useCallback } from 'react';

import Layout from '../components/Base/Layout';
import { SEO } from '../components/Base/Seo';
import { Text } from '@chakra-ui/react';
import { navigate } from 'gatsby';

const RestrictedPage: FunctionComponent = () => {
	return (
		<>
			<Layout>
				<div className={styles.magentaloyaltyclub}>
					<div className={styles.herosection}>
						<img
							className={styles.herobackgroundIcon}
							alt='herobackground'
							src='/dm-howtoorder-hero.webp'
						/>
						<div className={styles.overlay1} />
						<div className={styles.heroContent}>
							<div className={styles.heroText}>
								<h1 className={styles.headingLarge3xlRedirect}>
									Thank you for considering Doctor Medica.
								</h1>
								<Text
									fontSize='1.1rem'
									fontWeight='bold'>
									Unfortunately, the page you are attempting to access is
									currently unavailable to unregistered users.
								</Text>
								<div className={styles.sectionText}>
									To view the page, it is necessary to log in to your account.
									If you do not already have an account, you can click on the
									“Sign In” button and click on the "Register Here” link. After
									completing the registration process, you will gain access to
									our full range of products, including the one you are
									interested in.
								</div>
								<button
									onClick={() => navigate('/sign-in')}
									className={styles.lightButton}>
									<b className={styles.discoverProducts}>SIGN IN</b>
								</button>
								<Text
									className={styles.sectionText}
									fontSize='sm'>
									At Doctor Medica, we are committed to ensuring the security
									and privacy of our customers. By logging in to your account,
									you can be assured that your personal information and
									transactions are protected.
								</Text>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
};

export const Head = () => (
	<SEO
		title='Restricted | Doctor Medica Medical Supply Store'
		description='Learn how to order wholesale medical supplies from Doctor Medica online or by phone, fax, email. Register a new account to place your first order and save.'
	/>
);
export default RestrictedPage;
